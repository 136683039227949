import React, { useRef, useEffect } from "react";
import Slider from "react-slick";
import "./App.css"; // Import the stylesheet
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import donkeyMeditating from "./images/pp_logo.png";
import donkeyLifting from "./images/biden_pp.jpg";
import donkeyMorning from "./images/dragonball_pp.png";
import roofing from "./images/rocket_pp.png";
import backgroundMusic from "./assets/audio/background-music.mp3"; // Import the audio file

const App = () => {
  const audioRef = useRef(null);

  useEffect(() => {
    const playAudio = async () => {
      try {
        await audioRef.current.play();
        document.getElementById("play-button").style.display = "none";
      } catch (error) {
        console.error("Autoplay failed, showing play button:", error);
        document.getElementById("play-button").style.display = "block";
      }
    };

    playAudio();
  }, []);

  const handlePlayMusic = () => {
    audioRef.current.play().catch((error) => {
      console.error("Error attempting to play audio:", error);
    });
    document.getElementById("play-button").style.display = "none";
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 2000,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          speed: 2000,
        },
      },
    ],
  };

  return (
    <div className="app">
      <header className="app-header">
        <img
          src={donkeyMeditating}
          alt="Donkey on Solana Logo"
          className="logo"
        />
        <h1 style={{ fontFamily: "Pacifico, cursive" }}>
          PEEPEE ON SOLANA $PP
        </h1>
      </header>
      <main className="main-content">
        <p>ALL THINGS PeePee the Frog</p>
        <div className="link-container">
          <a
            href="https://t.me/PeePeexSol"
            target="_blank"
            rel="noopener noreferrer"
          >
            Telegram
          </a>
          <a
            href="https://x.com/PeePeeFrogSOL"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
          <a
            href="https://dexscreener.com/solana/FfKbf617RoZZLdngUntSNDSGx8bwxfNhW5acpJQSpump"
            target="_blank"
            rel="noopener noreferrer"
          >
            DexScreener
          </a>
        </div>
        <Slider {...settings} className="image-container">
          <div>
            <img
              src={donkeyMeditating}
              alt="Donkey Meditating"
              className="slider-image"
            />
          </div>
          <div>
            <img
              src={donkeyLifting}
              alt="Donkey Lifting Weights"
              className="slider-image"
            />
          </div>
          <div>
            <img
              src={donkeyMorning}
              alt="Donkey Enjoying Morning"
              className="slider-image"
            />
          </div>
          <div>
            <img src={roofing} alt="Roofing" className="slider-image" />
          </div>
        </Slider>
      </main>
      <footer className="app-footer">
        <p>THE REVENGE OF $PP WILL BE SO SWEET, DON'T MISS OUT</p>
      </footer>

      {/* Play Button */}
      <button
        id="play-button"
        onClick={handlePlayMusic}
        className="play-button"
      >
        Play Music
      </button>

      {/* Background music */}
      <audio ref={audioRef} loop>
        <source src={backgroundMusic} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

export default App;
